<template>
  <div class="property-search-bar">
    <bg-search-bar
      v-model="propertyName"
      :placeholder="placeholder"
      :suggestions="searchSuggestionLists"
      :loading="isSearchLoading"
      @input.native="handleTypingSearch"
      @click-suggestion="handleClickSuggestion"
      @keyup.enter="handleSubmitFilter"
    />
  </div>
</template>

<script>
import { BgSearchBar } from 'bangul-vue';
import debounce from 'Utils/debounce';
import propertyApi from '@admin_endpoints/property';

export default {
  name: 'PropertySearchBar',

  components: { BgSearchBar },

  props: {
    value: {
      type: String,
      required: true,
    },
    typingDelayTime: {
      type: Number,
      default: 500,
    },
    url: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      propertyName: '',
      searchSuggestionLists: [],
      isSearchLoading: false,
    };
  },

  watch: {
    propertyName(value) {
      this.$emit('input', value);
    },
    value: {
      immediate: true,
      handler(value) {
        if (value !== this.propertyName) {
          this.propertyName = value;
        }
      },
    },
  },

  methods: {
    /* eslint-disable prefer-arrow-callback, func-names */
    handleTypingSearch: debounce(
      async function ({ target: { value } }) {
        if (!value) {
          this.isSearchLoading = false;
          return;
        }

        this.searchSuggestionLists = [];

        try {
          const { data } = await propertyApi.getPropertySuggestions(
            this.url,
            value
          );
          this.searchSuggestionLists = data.data.map(property => ({
            ...property,
            label: property.name,
          }));
        } catch (error) {
          let errorMessage = '';
          if (error?.response.status === 404) {
            errorMessage = `Nama atau ID properti "${value}" tidak ditemukan.`;
          } else {
            errorMessage =
              error?.response.data.issue?.message ||
              'Terjadi kesalahan saat mencari properti.';
          }
          this.$toast.show(errorMessage);
        } finally {
          this.isSearchLoading = false;
        }
      },
      function () {
        return this.typingDelayTime;
      },
      function () {
        this.isSearchLoading = true;
      }
    ),
    /* eslint-enable prefer-arrow-callback, func-names */

    handleClickSuggestion(value) {
      this.propertyName = value.label;
    },

    handleSubmitFilter() {
      this.$emit('handle-submit-filter');
    },
  },
};
</script>

<style></style>
